/* eslint-disable import/no-cycle */
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Vue from 'vue'
import store from '@/store'
import { eventbus, eventBusEvents } from '@fiizy/utils'
import { v4 as uuidv4 } from 'uuid'

import {
  EventPayload,
  ExternalStepRequest,
  FieldPropertyChangeEvent,
  Form,
  FormField,
  FormFieldActionEvent,
  FormRequest,
  ObjectLiteral,
  Step,
  StepCount
} from '@/models'
import {
  fetchAutocompleteRequest,
  findProcessRequest,
  getProcessRequest,
  initProcessRequest,
  postExternalStep,
  postProcessRequest,
  postProcessRequestWithGivenUrl,
  postProcessSwitchRequest,
  profileHistoryDeleteRequest,
  resumeProcessRequest
} from '@/api/resources/process'
import validateFormField from '@/utils/formfieldvalidation'
import logger from '@/utils/logger'
import { isArray } from '@/utils/typeguards'
import AttributionModule from '@/store/modules/attribution'
import handleTrackingEvent from '@/utils/events/handleTrackingEvent'
import { findFieldIndex } from '@/utils/helpers'
import { environment } from '@/environments/environment'
import { stripDiacritics, toCamel } from '@/utils/transformer'
import { sortFormFields } from '@/utils/formbuilder'
import router from '@/router'
import logout from '@/utils/events/logout'

const bus = eventbus(environment.EVENT_CHANNEL || 'c432556660722ff93cefb5a932e550b7')
const { newDeepLink } = eventBusEvents

interface AutocompleteEventProperties {
  url?: string
  fields?: ObjectLiteral[]
  key?: string
}

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'process'
})
class Process extends VuexModule {
  loading: boolean = false

  fields: FormField[] = []

  step: Step | undefined = undefined

  steps: Step[] | undefined = undefined

  formValidationToken: string = ''

  affiliateRedirectUrl: string = ''

  showLoadingMessage: boolean = false

  get getLoading(): boolean {
    return this.loading
  }

  get getShowLoadingMessage(): boolean {
    return this.showLoadingMessage
  }

  get getFields(): FormField[] {
    return this.fields
  }

  get getStep(): Step | undefined {
    return this.step
  }

  get getSteps(): Step[] | undefined {
    return this.steps
  }

  get getProgress(): StepCount {
    let currentProgressPosition = 0
    let currentProgressMax = 0
    if (this.steps) {
      this.steps.forEach((step) => {
        if (step.properties.progress) {
          if (this.step && step.step === this.step.step) {
            currentProgressPosition = currentProgressMax + this.step.subStep
          }
          currentProgressMax += step.subSteps
        }
      })
    }

    return { currentProgressPosition, currentProgressMax }
  }

  get getProgressSubStep(): number {
    if (!this.step) {
      return 0
    }

    return this.step.subStep
  }

  get getFormValidation(): boolean {
    return this.fields.every((field) => {
      if (field.hidden) {
        return true
      }

      return validateFormField(field).isValid
    })
  }

  get getFormValidationToken(): string {
    return this.formValidationToken
  }

  get getBackButtonEnabled(): boolean {
    if (this.step?.properties?.leftButton && Object.keys(this.step.properties.leftButton).length > 0) {
      if (this.step.properties.leftButton.visibleInSubStep) {
        return Object.values(this.step.properties.leftButton.visibleInSubStep).includes(this.getProgressSubStep)
      }
      return true
    }
    return false
  }

  get getAffiliateRedirectUrl(): string {
    return this.affiliateRedirectUrl
  }

  @Mutation
  private SET_LOADING(loading: boolean) {
    this.loading = loading
  }

  @Mutation
  private SET_SHOW_LOADING_MESSAGE(loading: boolean) {
    this.showLoadingMessage = loading
  }

  @Mutation
  private SET_FIELDS(fields: FormField[]) {
    this.fields = fields
  }

  @Mutation
  private RESET_FIELDS() {
    this.fields = []
  }

  @Mutation
  private SET_STEP(step: Step) {
    this.step = step
  }

  @Mutation
  private SET_STEPS(steps: Step[]) {
    this.steps = steps
  }

  @Mutation
  private RESET_PROGRESS() {
    this.step = undefined
  }

  @Mutation
  private SET_FIELD_VALUE(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)

    if (index >= 0) {
      Vue.set(this.fields[index], 'value', payload.value)
    }
  }

  @Mutation
  private SET_FIELD_VALUES(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)

    if (index >= 0) {
      Vue.set(this.fields[index], 'values', payload.values)
    }
  }

  @Mutation
  private SET_FIELD_VALID(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)

    if (index >= 0) {
      Vue.set(this.fields[index], 'isValid', payload.isValid)
    }
  }

  @Mutation
  private UPDATE_FIELD(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)

    if (index >= 0) {
      Vue.set(this.fields, index, payload)
    }
  }

  @Mutation
  private HIDE_FIELD(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)

    if (index >= 0 && !this.fields[index].hidden) {
      Vue.set(this.fields[index], 'hidden', true)
      Vue.set(this.fields[index], 'hiddenInitially', true)
    }
  }

  @Mutation
  private SET_FIELD_LOADING(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)
    if (index >= 0) {
      Vue.set(this.fields[index], 'loadingContent', payload.loadingContent)
    }
  }

  @Mutation
  private SHOW_FIELD(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)

    if (index >= 0 && this.fields[index].hidden) {
      Vue.set(this.fields[index], 'hidden', false)
    }
  }

  @Mutation
  private ENABLE_FIELD(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)
    if (index >= 0 && this.fields[index].properties?.disabled) {
      this.fields[index].properties = { ...this.fields[index].properties, disabled: false }
    }
  }

  @Mutation
  private DISABLE_FIELD(payload: FormField) {
    const index = findFieldIndex(payload.fieldKey, this.fields)
    if (index >= 0 && !this.fields[index].properties?.disabled) {
      this.fields[index].properties = { ...this.fields[index].properties, disabled: true }
    }
  }

  @Mutation
  private SET_FORM_VALIDATION_TOKEN(formValidationToken: string) {
    this.formValidationToken = formValidationToken
  }

  @Mutation
  private SET_FIELD_TRANSFORMATION_VALUES(payload: {
    field: { fieldKey: string }
    valueAfterTransformation: string | undefined
    valueBeforeTransformation: string | undefined
  }) {
    const index = findFieldIndex(payload.field.fieldKey, this.fields)

    if (index >= 0) {
      Vue.set(this.fields[index], 'valueAfterTransformation', payload.valueAfterTransformation)
      Vue.set(this.fields[index], 'valueBeforeTransformation', payload.valueBeforeTransformation)
    }
  }

  @Mutation
  private SET_PROCESS_ERROR_MESSAGE(payload: { field: { fieldKey: string }; processErrorMsg: string }) {
    const index = findFieldIndex(payload.field.fieldKey, this.fields)
    if (index >= 0) {
      Vue.set(this.fields[index], 'processErrorMsg', payload.processErrorMsg)
    }
  }

  @Mutation
  private SET_FIELD_PROPERTY(payload: FieldPropertyChangeEvent) {
    const index = findFieldIndex(payload.fieldKey, this.fields)

    if (index >= 0) {
      Vue.set(this.fields[index], payload.name, payload.value)
    }
  }

  @Mutation
  private SET_AFFILIATE_REDIRECT_URL(redirectUrl: string) {
    this.affiliateRedirectUrl = redirectUrl
  }

  @Action({ rawError: true })
  async setForm(form: Form) {
    if (!form) {
      return
    }

    if (form.logout) {
      await store.dispatch('auth/authLogout', form.attribution)
    }

    if (form.attribution) {
      await store.dispatch('attribution/updateAttribution', form.attribution)
      await store.dispatch('tracking/setCampaignHash', form.attribution.campaignHash || '')
    }

    if (form.step) {
      store.commit('process/SET_STEP', form.step)
    }

    if (form.steps) {
      store.commit('process/SET_STEPS', form.steps)
    }

    if (form?.affiliateRedirectUrl) {
      store.commit('process/SET_AFFILIATE_REDIRECT_URL', form.affiliateRedirectUrl)
    }

    if (form?.notifications) {
      store.dispatch('notifications/setNotifications', form?.notifications)
    }

    if (!isArray(form.fields)) {
      form.fields = []
    }

    if (form.errors) {
      form.errors.forEach((error) => {
        if (error.field) {
          const field = form.fields.find(({ fieldKey }) => fieldKey === error.field)
          if (field) {
            field.hasError = true
            field.isValid = false
            field.processErrorMsg = error.message
          }
        }
      })
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const field of form.fields) {
      if (!field.hidden) {
        // this is actually read as component was sent to frontend
        handleTrackingEvent({
          field,
          action: 'shown'
        } as FormFieldActionEvent)
      }

      // field has to have default value for this so we can watch it later in case it gets populated
      if (!isArray(field.values)) {
        field.values = []
      }

      // in some cases API sends dynamic values for field. If so, then copy them to field values
      if (field.properties && isArray(field.properties.values)) {
        field.values = field.properties.values
      }

      if (field.properties?.default !== undefined) {
        const defaultValueFromFieldValues = field.values.find(
          (value) => value.value === field.properties?.default
        )?.value
        const fieldValueInFieldValues = field.values.find((value) => value.value === field.value)?.value

        if (fieldValueInFieldValues !== undefined) field.value = fieldValueInFieldValues
        else if (defaultValueFromFieldValues !== undefined) field.value = defaultValueFromFieldValues
      }

      if (field.value !== undefined && !field.properties?.prefillValueIsPartial) {
        if (field.hasError) field.isValid = false
        else {
          const validatedField = validateFormField(field)
          field.isValid = validatedField.isValid
          field.noticeMsg = validatedField.noticeMsg
        }

        // in case it is a field with dynamic values and they are empty, we dont want to send ffa as it will be incorrect
        if (
          (field.fieldType !== 'dropdown_searchable' &&
            field.fieldType !== 'select_autocomplete' &&
            field.fieldType !== 'select_fetch') ||
          field.values?.length !== 0
        ) {
          handleTrackingEvent({
            field,
            action: !field.hasError && field.isValid ? 'prefilled_validated_correct' : 'prefilled_validated_incorrect'
          } as FormFieldActionEvent)
        }
      } else {
        field.isValid = false
      }

      field.id = uuidv4()
      field.hiddenInitially = false
    }

    store.commit('process/SET_FIELDS', [...form.fields].sort(sortFormFields))

    bus.emit(newDeepLink)

    if (form.formValidationToken) {
      store.commit('process/SET_FORM_VALIDATION_TOKEN', form.formValidationToken)
    }
  }

  @Action({ rawError: true })
  async validateForm(): Promise<boolean> {
    for (const field of this.fields) {
      if (field.hidden) {
        // eslint-disable-next-line no-continue
        continue
      }

      if (!validateFormField(field).isValid) {
        this.updateField({
          ...field,
          isValid: false,
          hasError: true
        })
        break
      }
    }

    return this.getFormValidation
  }

  @Action({ commit: 'SET_LOADING' })
  setLoading(loading: boolean) {
    return loading
  }

  @Action({ rawError: true })
  async setProcessForm({
    processRequest,
    formRequest,
    targetProcess,
    apiRequestUrl
  }: {
    processRequest: any
    formRequest: FormRequest
    targetProcess: string
    apiRequestUrl: string
  }): Promise<Form | null> {
    // if form/process request is already in progress then don't make another :perhaps:
    if (this.loading) return null

    this.SET_LOADING(true)

    let form: Form | null

    const messageTimer = setTimeout(() => {
      store.commit('process/SET_SHOW_LOADING_MESSAGE', true)
    }, 1000 * 7)

    if (targetProcess) {
      form = await processRequest({ form: formRequest, targetProcess, apiRequestUrl })
    } else {
      form = await processRequest(formRequest, apiRequestUrl)
    }

    if (form?.notifications) {
      store.dispatch('notifications/setNotifications', form?.notifications)
    }

    if (form?.step?.completed && form.targetProcess && form.targetProcess.processName !== 'PROFILE') {
      // When redirecting to target process, sometimes we don`t want the previously submitted form data submitted again
      // for target process, so we allow back to specify if we want the form to be reset before fetching target process.
      if (form.targetProcess.formReset) {
        delete formRequest.form
      }
      form = await postProcessRequest({
        ...formRequest,
        attribution: {
          // Use the attribution returned by the backend in the previous request as it might have changed.
          ...form.attribution,
          processName: form.targetProcess.processName,
          processSessionHash: form.targetProcess.processSessionHash
        }
      })
    }
    if (messageTimer) {
      clearTimeout(messageTimer)
      store.commit('process/SET_SHOW_LOADING_MESSAGE', false)
    }

    if (form?.authLinkHash) {
      await store.dispatch('auth/authLogin', {
        channelHash: AttributionModule.getChannelHash as string,
        authLinkHash: form?.authLinkHash,
        grantType: 'magic_link'
      })
    }

    if (form) {
      if (form.targetProcess && form.targetProcess.processName === 'PROFILE') {
        if (window.location.href.includes('login')) await router.push({ name: 'profile' })
        else window.location.href = '/profile'
      }

      await store.dispatch('process/setForm', form)
    }

    this.SET_LOADING(false)

    return form
  }

  // initial request to form builder api returns form and attribution
  @Action({ rawError: true })
  async findProcess(formRequest: FormRequest) {
    return store.dispatch('process/setProcessForm', {
      processRequest: findProcessRequest,
      formRequest
    })
  }

  // first form submit, creates processSessionHash and returns form
  @Action({ rawError: true })
  async initProcess(formRequest: FormRequest) {
    return store.dispatch('process/setProcessForm', { processRequest: initProcessRequest, formRequest })
  }

  // resume process form submit, returns processSessionHash and form
  @Action({ rawError: true })
  async resumeProcess(formRequest: FormRequest) {
    return store.dispatch('process/setProcessForm', { processRequest: resumeProcessRequest, formRequest })
  }

  // regular form submit, returns next form page or the same if error or user refreshed page
  @Action({ rawError: true })
  async postProcess(formRequest: FormRequest): Promise<Form | undefined> {
    const { processSessionHash } = formRequest.attribution

    if (!processSessionHash) {
      logger.error('missing processSessionHash')

      return undefined
    }

    return store.dispatch('process/setProcessForm', {
      processRequest: postProcessRequest,
      formRequest: {
        ...formRequest,
        attribution: { ...formRequest.attribution, processName: formRequest.attribution.processName || 'find' }
      }
    })
  }

  @Action({ rawError: true })
  async postProcessSwitch({
    formRequest,
    targetProcess
  }: {
    formRequest: FormRequest
    targetProcess: string
  }): Promise<Form | undefined> {
    const { processSessionHash } = formRequest.attribution

    if (!processSessionHash) {
      logger.error('missing processSessionHash')

      return undefined
    }

    return store.dispatch('process/setProcessForm', {
      processRequest: postProcessSwitchRequest,
      formRequest: {
        ...formRequest,
        attribution: { ...formRequest.attribution, processName: formRequest.attribution.processName || 'find' }
      },
      targetProcess
    })
  }

  @Action({ rawError: true })
  async upsellRemLinkProcessPost({ formRequest, apiRequestUrl }: { formRequest: FormRequest; apiRequestUrl: string }) {
    return store.dispatch('process/setProcessForm', {
      processRequest: postProcessRequestWithGivenUrl,
      formRequest,
      apiRequestUrl
    })
  }

  @Action({ rawError: true })
  async resetApplication() {
    store.commit('process/RESET_FIELDS')
    store.commit('process/RESET_PROGRESS')
  }

  @Action({ commit: 'HIDE_FIELD', rawError: true })
  hideField(payload: FormField) {
    return payload
  }

  @Action({ commit: 'SHOW_FIELD', rawError: true })
  showField(payload: FormField) {
    return payload
  }

  @Action({ commit: 'ENABLE_FIELD', rawError: true })
  enableField(payload: FormField) {
    return payload
  }

  @Action({ commit: 'DISABLE_FIELD', rawError: true })
  disableField(payload: FormField) {
    return payload
  }

  @Action({ rawError: true })
  async setFieldValue(payload: FormField) {
    const existing = this.fields.find(({ fieldKey }) => fieldKey === payload.fieldKey)

    if (existing && existing.value !== payload.value) {
      const validatedField = validateFormField({ ...existing, ...payload, hasError: false, isValid: true })
      store.commit('process/SET_FIELD_VALUE', validatedField)
      store.commit('process/SET_FIELD_VALID', validatedField)

      if (existing.noticeMsg !== validatedField.noticeMsg) {
        store.commit('process/SET_FIELD_PROPERTY', {
          ...validatedField,
          name: 'noticeMsg',
          value: validatedField.noticeMsg
        })
      }

      if (existing.customErrorMsg !== validatedField.customErrorMsg) {
        store.commit('process/SET_FIELD_PROPERTY', {
          ...validatedField,
          name: 'customErrorMsg',
          value: validatedField.customErrorMsg
        })
      }

      if (validatedField.processErrorMsg) {
        store.commit('process/SET_PROCESS_ERROR_MESSAGE', { field: validatedField, processErrorMsg: '' })
      }
    }
  }

  @Action({ rawError: true })
  async setFieldProperty(payload: FieldPropertyChangeEvent) {
    const field = this.fields.find(({ fieldKey }) => fieldKey === payload.fieldKey)

    if (field) {
      store.commit('process/SET_FIELD_PROPERTY', payload)
    }
  }

  @Action({ rawError: true })
  setFieldValid(payload: FormField) {
    store.commit('process/SET_FIELD_VALID', payload)
  }

  @Action({ rawError: true })
  updateField(payload: FormField) {
    const existing = this.fields.find(({ fieldKey }) => fieldKey === payload.fieldKey)

    if (existing) {
      store.commit('process/UPDATE_FIELD', payload)
    }
  }

  @Action({ rawError: true })
  async fetchAutoComplete(payload: EventPayload): Promise<void | FormField> {
    if (!payload.value && typeof payload.value !== 'string') {
      return
    }

    const eventProperties = (payload.properties ? payload.properties : {}) as AutocompleteEventProperties

    const field = this.fields.find(({ fieldKey }) => fieldKey === payload.key)
    if (!field || !eventProperties.url) {
      return
    }

    this.SET_LOADING(true)
    store.commit('process/SET_FIELD_LOADING', { ...field, loadingContent: true })

    let { url } = eventProperties
    const replacements = url.match(/[^{]+(?=})/g)

    if (replacements) {
      replacements.forEach((value) => {
        const newField = this.fields.find(({ fieldKey }) => fieldKey === value)
        if (newField) {
          url = url?.replace(`{${newField.fieldKey}}`, newField.value as string)
        }
      })
    }

    const form = await fetchAutocompleteRequest(url as string, payload.value as string, 'value')
    const trackingEvent: FormFieldActionEvent = {
      field,
      action: 'filled_by_event_validated_correct'
    }
    handleTrackingEvent(trackingEvent)

    this.SET_LOADING(false)
    store.commit('process/SET_FIELD_LOADING', { ...field, loadingContent: false })

    if (field && form && form.data && Object.keys(form.data).length && eventProperties.key) {
      const apiValues = Object.values(form.data[toCamel(eventProperties.key)] || {})
      const values = apiValues.map((value) => ({
        key: value,
        label: value,
        text: value,
        searchableValue: stripDiacritics(value),
        value
      }))

      if (values.length === 0 && field.properties?.allowNewValue) {
        store.dispatch('process/updateField', {
          ...field,
          value: payload.value,
          isValid: true,
          hasError: false,
          values: [
            { key: payload.value, label: payload.value, text: payload.value, value: payload.value, customValue: true }
          ],
          properties: { ...field.properties, disabled: false }
        })

        // eslint-disable-next-line consistent-return
        return { ...field, values: [{ key: field.value, label: field.value, text: field.value, value: field.value }] }
      }

      const fieldValue = apiValues
        .map((value) => value.toLowerCase())
        .includes(field.value?.toString().toLowerCase() || '')
        ? field.value
        : ''

      const validatedField = validateFormField({
        ...field,
        value: fieldValue,
        values,
        properties: { ...field.properties, disabled: false }
      })

      store.dispatch('process/updateField', validatedField)

      // eslint-disable-next-line consistent-return
      return { ...field, values }
    }

    // when api does not respond with data and custom new value is allowed then set it anyways - PL company_name field
    if (!form?.data && field.properties?.allowNewValue) {
      store.dispatch('process/updateField', {
        ...field,
        value: payload.value,
        isValid: true,
        hasError: false,
        values: [
          { key: payload.value, label: payload.value, text: payload.value, value: payload.value, customValue: true }
        ],
        properties: { ...field.properties, disabled: false }
      })

      // eslint-disable-next-line consistent-return
      return { ...field, values: [{ key: field.value, label: field.value, text: field.value, value: field.value }] }
    }

    store.dispatch('process/setFieldValid', {
      ...field,
      isValid: false,
      hasError: true
    })
    store.commit('process/SET_FIELD_VALUES', { ...field, values: [] })

    // eslint-disable-next-line consistent-return
    return { ...field, values: [] }
  }

  @Action({ rawError: true })
  async autoCompleteSelect(payload: EventPayload) {
    const eventProperties = (payload.properties ? payload.properties : {}) as AutocompleteEventProperties

    if (eventProperties.fields) {
      eventProperties.fields.forEach((field) => {
        if (field.field !== payload.origin && payload.value.hasOwnProperty(toCamel(field.value))) {
          const value = (payload.value as ObjectLiteral)[toCamel(field.value)]
          this.setFieldValue({
            fieldKey: field.field,
            value
          } as FormField)

          const target = this.fields.find((f) => f.fieldKey === field.field)

          if (!target) return

          const trackingEvent: FormFieldActionEvent = {
            field: target,
            action: 'filled_by_event_validated_correct'
          }
          handleTrackingEvent(trackingEvent)
        }
      })
    }
  }

  @Action({ rawError: true })
  async setTransformationValues(payload: {
    valueAfterTransformation: string | undefined
    valueBeforeTransformation: string | undefined
    field: FormField
  }) {
    store.commit('process/SET_FIELD_TRANSFORMATION_VALUES', payload)
  }

  @Action
  async fetchFields({ channelHash, processName }: { channelHash?: string; processName: string }) {
    store.dispatch('process/setLoading', true)

    const form = await getProcessRequest({
      attribution: { processName, channelHash }
    } as FormRequest)

    if (form) {
      store.dispatch('process/setForm', form)
    }

    store.dispatch('process/setLoading', false)
    return form
  }

  @Action
  async deleteProfile() {
    store.dispatch('process/setLoading', true)
    const response = await profileHistoryDeleteRequest()

    if (response) {
      await logout()

      return true
    }

    logger.error('No data in profile delete request.', response)

    store.dispatch('process/setLoading', false)
    return false
  }

  @Mutation
  private SET_FIELD_VALUES_FILTER_GROUP(payload: EventPayload) {
    const index = findFieldIndex(payload.key, this.fields)

    if (index >= 0) {
      Vue.set(this.fields[index], 'filterGroup', payload.value)
      // Vue.set(this.fields[index], 'value', undefined)
    }
  }

  @Action({ rawError: true })
  setFieldValuesFilterGroup(payload: EventPayload) {
    store.commit('process/SET_FIELD_VALUES_FILTER_GROUP', payload)
  }

  @Action
  async postExternalStep() {
    store.dispatch('process/setLoading', true)

    const response = await postExternalStep({
      applicationHash: AttributionModule.getApplicationHash,
      campaignHash: AttributionModule.getCampaignHash
    } as ExternalStepRequest)

    store.dispatch('process/setLoading', false)

    return response
  }

  @Action
  checkAllCheckboxes(): void {
    const checkAllCheckboxesField = this.fields.find((field) => field.fieldType === 'check_all_checkboxes')

    if (!checkAllCheckboxesField) {
      return
    }

    const filterCheckboxes = (checkAllCheckboxes: FormField, field: FormField) => {
      if (field.fieldType !== 'checkbox' || field.value === true) {
        return false
      }

      if (
        !checkAllCheckboxes.properties ||
        !checkAllCheckboxes.properties.group ||
        checkAllCheckboxes.properties.group === ''
      ) {
        return true
      }

      if (
        field.properties &&
        field.properties.group &&
        checkAllCheckboxes.properties.group === field.properties.group
      ) {
        return true
      }

      return false
    }

    this.fields
      .filter((field) => filterCheckboxes(checkAllCheckboxesField, field))
      .forEach((field) => {
        this.setFieldValue({
          fieldKey: field.fieldKey,
          value: true
        } as FormField)
        handleTrackingEvent({
          field,
          action: 'validated_correct'
        })
      })
  }
}

const ProcessModule = getModule(Process)

export default ProcessModule
