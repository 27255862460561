/* eslint-disable import/no-cycle */
import apiService from '@/api'
import {
  ExternalStepRequest,
  ExternalStepResponse,
  Form,
  FormRequest,
  ObjectLiteral,
  OfferResponse,
  PagingResponse
} from '@/models'
import logger from '@/utils/logger'
import AuthModule from '@/store/modules/auth'

export async function findProcessRequest(form: FormRequest): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  const resourcePath = `/processes`

  // set content type to text/plain to prevent pre-flight request (CORS)
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
  return apiService
    .post<Form>(resourcePath, form, { headers: { 'Content-Type': 'text/plain' } })
    .catch((error: Error) => {
      logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

      return undefined
    })
}

export async function initProcessRequest(form: FormRequest): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  const {
    attribution: { processName, channelHash }
  } = form
  const resourcePath = `/processes/${processName?.toUpperCase()}/channels/${channelHash}`

  return apiService.post<Form>(resourcePath, form).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function getProcessRequest(form: FormRequest): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  const {
    attribution: { processName, channelHash }
  } = form
  const resourcePath = `/processes/${processName?.toUpperCase()}/channels/${channelHash}`

  return apiService.get<Form>(resourcePath).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function postProcessRequest(form: FormRequest): Promise<Form | null> {
  await AuthModule.isUserAuth()

  const {
    attribution: { processName, channelHash, processSessionHash }
  } = form
  const resourcePath = `/processes/${processName?.toUpperCase()}/channels/${channelHash}/sessions/${processSessionHash}`

  return apiService.post<Form>(resourcePath, form).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return null
  })
}

export async function postProcessFieldEventRequest(eventHash: string, form: FormRequest): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  const {
    attribution: { processName, channelHash, processSessionHash }
  } = form
  const resourcePath = `/processes/${processName?.toUpperCase()}/channels/${channelHash}/sessions/${processSessionHash}/fields/events/${eventHash}`

  return apiService.post<Form>(resourcePath, form).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function postProcessSwitchRequest({
  form,
  targetProcess
}: {
  form: FormRequest
  targetProcess: string
}): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  const {
    attribution: { processName, channelHash, processSessionHash }
  } = form

  const resourcePath = `/processes/${processName?.toUpperCase()}/channels/${channelHash}/sessions/${processSessionHash}/switch/${targetProcess}`

  return apiService.post<Form>(resourcePath, form).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function resumeProcessRequest(form: FormRequest): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  const {
    attribution: { processName }
  } = form
  const resourcePath = `/processes/${processName?.toUpperCase()}/resume`

  return apiService.post<Form>(resourcePath, form).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function postProcessRequestWithGivenUrl(
  form: FormRequest,
  apiRequestUrl: string
): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  return apiService.post<Form>(apiRequestUrl, form).catch((error: Error) => {
    logger.error(`Error loading resource ${apiRequestUrl}`, { requestErrorObject: error })

    return undefined
  })
}

export async function postKontomatikSession(form: FormRequest): Promise<Form | undefined> {
  await AuthModule.isUserAuth()

  const resourcePath = '/kontomatik/session'

  return apiService.post<Form>(resourcePath, form).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function fetchAutocompleteRequest(
  path: string,
  replacement: string | number | boolean,
  placeholder?: string
): Promise<PagingResponse | undefined> {
  await AuthModule.isUserAuth()

  const resourcePath = path.replace(`{${placeholder || 'value'}}`, replacement as string).replace('api/v2', '')
  return apiService.get<PagingResponse>(resourcePath).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function getOffers(offersURL: string): Promise<OfferResponse | undefined> {
  await AuthModule.isUserAuth()

  return apiService.get<OfferResponse>(offersURL).catch((error: Error) => {
    logger.error(`Error loading resource ${offersURL}`, { requestErrorObject: error })

    return undefined
  })
}

export async function profileHistoryDeleteRequest(): Promise<ObjectLiteral[] | undefined> {
  await AuthModule.isUserAuth()

  const resourcePath = '/profile/delete'
  return apiService.get<ObjectLiteral[]>(resourcePath).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function postExternalStep(request: ExternalStepRequest): Promise<ExternalStepResponse | undefined> {
  await AuthModule.isUserAuth()

  const resourcePath = '/externalstep'

  return apiService.post<ExternalStepResponse>(resourcePath, request).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function getOfferStatus(offerStatusURL: string): Promise<{ status: string } | undefined> {
  await AuthModule.isUserAuth()

  return apiService.get<{ status: string }>(offerStatusURL).catch((error: Error) => {
    logger.error(`Error loading resource ${offerStatusURL}`, { requestErrorObject: error })

    return undefined
  })
}
